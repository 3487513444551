<template>
  <div class="About">
    <div class="about-logo-box">
      <img class="about-logo" :src="image_url + '/3qlab_logo.png'">
    </div>
    <div class="about-intro-box">
      <div class="about-eng-title">ABOUT 3Q LAB</div>
      <div class="about-chs-box">
        <div class="about-chs-title">关于三驱</div>
        <div class="about-line"></div>
        <div class="about-circle">></div>
        <div class="about-more"><router-link to="/Base">了解更多</router-link></div>
      </div>
      <div class="about-info">
        <p>诞生于2019年7月3日</p>
        <p>由科学、艺术和技术领域</p>
        <p>专业人士组成的</p>
        <p>去中心化自治共同体。</p>
      </div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "home_about",
  data() {
    return {
      image_url: image_url
    }
  }
}
</script>

<style scoped lang="scss">
  .About {
    width: 1200px;
    margin: 100px auto 200px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-logo-box {
    width: 50%;
    padding: 0 50px 0 150px;
  }

  .about-intro-box {
    width: 50%;
    padding: 0 100px;
  }

  .about-logo {
    // width: 395px;
    // height: 144px;
    width: 100%;
  }

  .about-eng-title {
    margin: 5px 0;
    width: 113px;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
  }
  
  .about-chs-box {
    display: flex;
    align-items: center;
  }

  .about-chs-title {
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
    width: 50%;
  }
  
  .about-line {
    width: 82px;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .about-circle {
    width: 38px;
    height: 38px;
    border-radius: 30px;
    border: 1px solid #FFFFFF;
    font-size: 20px;
    color: #FFFFFF;
    text-align: center;
    line-height: 35px;
  }
  
  .about-more {
    font-size: 14px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    margin-left: 10px;
    cursor: pointer;
    user-select: none;

    a{
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    }
  }

  .about-info {
    margin-top: 30px;
    padding-left: 5px;
    p {
        font-size: 14px;
        font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
    }
  }
</style>