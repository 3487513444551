<template>
  <div class="Industry">
    <div class="industry-eng-title">
      INDUSTRIAL LAYOUT
    </div>
    <div class="industry-chs-box">
        <div class="industry-line industry-left-line"></div>
        <div class="industry-chs-title">三一棋局</div> 
        <div class="industry-line industry-right-line"></div>
    </div>
    <div class="industry-icon">
        <img class="industry-icon" :src="image_url + '/Home/industrial_icon.png'"/>
    </div>
    <div class="industry-intro">
        <p>3QLAB致力于构建全新的去中心化自治体，产业</p>
        <p>分布涉足科研、艺术、金融三大领域。目前已</p>
        <p>在杭州、苏州、嘉兴、新加坡、香港等地注册</p>
        <p>运营，在未来我们将逐步构建起一个由科研、</p>
        <p>艺术、金融三大板块组合的去中心化自治共同体。</p>
    </div>
    <div class="industry-map">
        <img class="map-bg" :src="image_url + '/Home/industrial_bg_2.png'"/>
        <div class="map-point" style="top: 26%; left: 28%;">
          <div class="map-point-dialoge"><router-link to="/ChessGame#research">科研领域</router-link></div>
          <img class="map-point-icon" :src="image_url + '/Home/map_icon.jpg'" />
        </div>
        <div class="map-point" style="top: 32%; left: 46%;">
          <div class="map-point-dialoge"><router-link to="/ChessGame#art">艺术领域</router-link></div>
          <img class="map-point-icon" :src="image_url + '/Home/map_icon.jpg'" />
        </div>
        <div class="map-point" style="top: 32%; left: 63%;">
          <div class="map-point-dialoge"><router-link to="/ChessGame#finance">金融领域</router-link></div>
          <img class="map-point-icon" :src="image_url + '/Home/map_icon.jpg'" />
        </div>
        <div class="industry-more-btn"><router-link to="/ChessGame">了解更多</router-link></div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "home_industry",
  data() {
    return {
      image_url: image_url,
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
  .Industry {
    width: 100%;
    margin: 0 auto;
  }

  .industry-eng-title {
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 30px;
  }
  
  .industry-chs-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .industry-chs-title {
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .industry-line {
    width: 35%;
    height: 1px;
    border: 1px solid;
    margin: 0 20px;
  }

  .industry-left-line {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .industry-right-line {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
  }

  .industry-icon {
    text-align: center;
    margin: 10px auto;
    img {
        width: 75px;
        height: 75px;
    }
  }

  .industry-intro {
    width: 30%;
    margin: 20px auto;
    text-align: center;
    position: relative;
    z-index: 1;
    p {
        font-size: 13px;
        font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
    }
  }

  .industry-map {
    position: relative;
    z-index: 0;
    height: 800px;
    margin-top: -70px;
  }

  .map-bg {
    position: absolute;
    bottom: 0;
    //height: 400px;
    width: 100%;
    height: 100%;
  }

  .industry-more-btn {
    margin: 0 auto;
    width: 150px;
    height: 40px;
    background: #000000;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    position: relative;
    top: 600px;
    cursor: pointer;

    a{
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    }
  }

  .map-point {
    width: 143px;
    position: absolute;
    text-align: center;
  }

  .map-point-dialoge {
    height: 50px;
    font-size: 15px;
    line-height: 40px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    background: url(https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Home/map_dialoge_small.png);

    a{
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    }
  }

  .map-point-dialoge:hover a {
    color: #000000; 
  } 
  
  .map-point-dialoge:hover {
    background: url(https://3qlab-website.oss-cn-hangzhou.aliyuncs.com/image/2023-1-26-web/Home/map_dialoge_white.png); 
  }
  .map-point-icon {
    margin-top: 5px;
    height: 35px;
    width: 35px;
  }
</style>