<template>
  <div class="Product">
    <div class="title-box" :style="titleBg">
        <div class="eng-title">
            PRODUCTS
        </div>
        <div class="chs-title">
            产品介绍
        </div>
        <div class="intro">
            <p>3QLAB聚力打造自治化社区组织，涉足科学、艺术、技术三大领域，倡导群</p>
            <p>体性智慧，呼吁交叉学科碰撞，尊重每位成员的贡献。这里有文学挑战、艺术</p>
            <p>平台和跨学科社区，这里鼓励参与者成为贡献者，欢迎贡献者成为决策者，</p>
            <p>3QLAB永远为探索家和创新者敞开大门。</p>
        </div>
        <div class="indexes">
            <div class="item"><router-link to="/Product#picture">万人添画SaaS</router-link></div>
            <div class="item"><router-link to="/Product#book">万人添书</router-link></div>
            <div class="item"><router-link to="/Product#3qapp">3QLAB App</router-link></div> 
        </div>
    </div>
    <div class="info-box">
      <div class="info">
        <div class="info-eng-title">PAINTING FOR 10,000 PEOPLE</div>
        <div class="info-chs-box">
          <div class="info-chs-title">万人添画</div>
          <div class="info-line"></div>
          <div class="info-circle">></div>
          <div class="info-more"><router-link to="/TenThousand#picture">了解更多</router-link></div>
        </div>
        <div class="info-img-box">
          <div class="info-img" :style="paintingImg" id="painting-img"/>
          <div class="info-intro">
            <div class="info-intro-detail">时空蚂蚁NFT</div>
            <div class="info-intro-btn"><router-link to="/TenThousand#picture">了解更多</router-link></div>
          </div>
        </div>
        <div class="info-detail">
          万人添画活动是由3QLAB主办的群体共创NFT活动，参与者们在共创艺术SaaS平台中自由地抒发情感、表达哲思、记录回忆、描绘期待。个人的思想在巨幅画作中有机拼合，个体融入群体，艺术与数字技术碰撞，“万人”共创这个时代独一无二的艺术作品。
        </div>
      </div>
      <div class="info">
        <div class="info-eng-title">Merged novels</div>
        <div class="info-chs-box">
          <div class="info-chs-title">万人添书</div>
          <div class="info-line"></div>
          <div class="info-circle">></div>
          <div class="info-more"><router-link to="/TenThousand#book">了解更多</router-link></div>
        </div>
        <div class="info-img-box">
          <div class="info-img" :style="bookImg" id="book-img"/>
          <div class="info-intro">
            <div class="info-intro-detail">七天穿越</div>
            <div class="info-intro-btn"><router-link to="/TenThousand#book">了解更多</router-link></div>
          </div>
        </div> 
        <div class="info-detail">
          万人添书活动是由3QLAB主办的线上多人共创文学活动，由群体智慧催生天马行空的遐想，以区块链存证保障个人的动力。参与者们围绕同一主题组成共创队伍完成文学挑战，尽情发挥群体的智慧与可能性，在文学的世界开辟穷极想象力的新宇宙。 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "home_product",
  data() {
    return {
      image_url: image_url,
      titleBg: {
        background: 'url(' + image_url + '/Home/product_bg.png' + ')',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      },
      paintingImg: {
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(' + image_url + '/Home/painting.jpg' + ')',
        backgroundSize: '250%',
        backgroundPosition: '-200px -60px'
      },
      bookImg: {
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(' + image_url + '/Home/books.jpg' + ')',
        backgroundSize: 'cover'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .Product {
    width: 1200px;
    margin: 0 auto;
  }
  
  .title-box {
    padding-top: 50px;
    height: 400px;
  }

  .eng-title {
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 30px;
  }

  .chs-title {
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .intro {
    width: 50%;
    margin: 40px auto;
    text-align: center;
    p {
        font-size: 13px;
        font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
    }
  }

  .indexes {
    margin: 80px auto 0 auto;
    display: flex;
    width: 70%;
  }

  .item {
    text-align: center;
    width: 33.3%;
    font-size: 18px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #7C5FD7;
    line-height: 24px;

    a{
      text-decoration: none;
      color: #7C5FD7;
      outline: none;
      padding: 10px 0;
    }

    a:hover {
      color: white;
      border-top: 1px solid white;
    }
  }


  .info-box {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }

  .info {
    width: 50%;
    padding: 80px;
  }

  .info-chs-box {
    display: flex;
    align-items: center;
  }

  .info-eng-title {
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 16px;
  }

  .info-chs-title {
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
    width: 50%;
  }

  .info-line {
    width: 82px;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .info-circle {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid #FFFFFF;
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    line-height: 26px;
  }
  
  .info-more {
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 24px;
    margin-left: 10px;
    cursor: pointer;
    a{
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    } 
  }

  .info-img-box {
    margin-top: 50px;
  }

  .info-img {
    height: 400px;
    width: 350px;
    border-radius: 20px;
  }

  // #painting-img {
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(/images/Home/painting.jpg);
  //   background-size: 250%;
  //   background-position: -200px -60px;
  // }

  // #book-img {
  //   background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)), url(/images/Home/books.jpg);
  //   background-size: cover;
  // }

  .info-intro {
    display: flex;
    width: 350px;
    height: 76px;
    background: linear-gradient(to bottom, #3D75E4 0%, #7C5FD7 100%);
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -76px;
  }

  .info-intro-detail {
    font-size: 16px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 16px;
    width: 55%;
  }

  .info-intro-btn {
    width: 30%;
    padding: 10px 0;
    border-radius: 20px;
    border: 1px solid #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    a{
      text-decoration: none;
      color: #FFFFFF;
      outline: none;
    }
  }

  .info-detail {
    width: 350px;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 25px;
    margin-top: -30px;
  }
</style>
