<template>
  <div class="Member">
    <div class="eng-title">BACKWAVE HIVE</div>
    <div class="title-box">
        <div class="chs-title">后浪蜂巢</div>
        <div class="title-line"></div>
        <div class="title-circle"></div>
    </div>
    <div class="member-box">
        <div v-if="members_info.length > 0" class="member-detail-box">
            <div class="member-detail-purple" :style="purpleDialoge"></div>
            <div class="member-detail-black" :style="blackDialoge"></div>
            <div class="member-detail-quote" :style="quote"></div> 
            <div class="member-detail-text">
                {{members_info[cur_members_idx].intro}}
            </div>
            <div class="member-detail-avatar">
                <img class="avatar-img" :src="image_url + '/Home/members/' + members_info[cur_members_idx].img"/>
                <div class="avatar-name">{{members_info[cur_members_idx].name}}</div>
                <div class="avatar-nickname">{{members_info[cur_members_idx].nickname}}</div>
            </div>
        </div>
        <div class="member-list-box swiper-container">
            <div class="swiper-wrapper">
              <div v-for="page in page_num" class="swiper-slide" :key="'slide' + page">
                <div v-for="i in 4" :key="'member_row' + i">
                    <div class="member-list-row">
                        <div class="member-list-item" v-for="j in 3" :key="'member_item' + (num_per_page * (page - 1) + i * 3 + j)">
                            <div v-if="num_per_page * (page - 1) + (i - 1) * 3 + j - 1 < members_info.length" :style="num_per_page * (page - 1) + (i - 1) * 3 + j - 1 == cur_members_idx ? selectedAvatar : null" class="member-avatar" @mouseover="changeCurMember(num_per_page * (page - 1) + (i - 1) * 3 + j - 1)">
                                <img class="avatar-img" :src="image_url + '/Home/members/' + members_info[num_per_page * (page - 1) + (i - 1) * 3 + j - 1].img"/>
                                <div class="avatar-name">{{members_info[num_per_page * (page - 1) + (i - 1) * 3 + j - 1].name}}</div>
                                <div class="avatar-nickname">{{members_info[num_per_page * (page - 1) + (i - 1) * 3 + j - 1].nickname}}</div>
                            </div>
                        </div>
                    </div>
                </div> 
              </div>
            </div>
        </div>
        <div class="pages-icon">
            <div v-for="i in page_num" :key="i">
                <div @click="changePage(i)" class="page-circle" :class="{'selected-circle': cur_page == i}"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';
// import {members_info } from '../const';
import { getMembers } from "@/api/Home";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";

export default {
    name: "home_member",
    data() {
        return {
            num_per_page: 12,
            cur_page: 1,
            members_info: [],
            cur_members_idx: 0,
            swiper: null,
            image_url: image_url,
            purpleDialoge: {
              background: 'url(' + image_url + '/Home/purple_dialoge.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            },
            blackDialoge: {
              background: 'url(' + image_url + '/Home/black_dialoge.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat'
            },
            quote: {
              background: 'url(' + image_url + '/Home/quote.png)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat' 
            },
            selectedAvatar: {
              background: 'url(' + image_url + '/Home/gradual_box.jpg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat' 
            }
        };
    },
    computed: {
      page_num: function() {
        return Math.floor((this.members_info.length + this.num_per_page - 1) / this.num_per_page);
      }
    },
    mounted() {
        let that = this;
        getMembers().then(res => {
          this.members_info = res.data;
        });
        
        let interval = setInterval(() => {
          if (that.members_info.length > 0) {
            that.swiper = new Swiper(".swiper-container", {
              direction: 'vertical',
            });
            clearInterval(interval);
          }
        }, 500);
    },

    methods: {
        changeCurMember(idx) {
            this.cur_members_idx = idx;
        },
        changePage(page) {
          this.cur_page = page;
          this.cur_members_idx = this.num_per_page * (page - 1);
          this.swiper.slideTo(page - 1);
        }
    }
}
</script>

<style scoped lang="scss">
  .Member {
    width: 1200px;
    margin: 0 auto;
  }
  
  .title-box {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80%;
  }
  
  .eng-title {
    width: 80%;
    margin: 0 auto;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 25px;
  }

  .chs-title {
    width: 30%;
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .title-line {
    width: 65%;
    height: 1px;
    border: 1px solid;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .title-circle {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid #FFFFFF;
    font-size: 15px;
    color: #FFFFFF;
    text-align: center;
    line-height: 26px;
  }

  .member-box {
    display: flex;
    align-items: center;
    width: 98%;
    margin-top: 25px;
    height: 620px;
  }

  .member-detail-box {
    width: 45%;
    height: 100%;
    position: relative;
    top: 60px;
    left: 110px;
    z-index: 0;
  }

  .member-list-box {
    width: 40%;
    height: 100%;
    z-index: 1;
  }

  .member-list-row {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin: 15px 0;
  }
  
  .member-list-item {
    width: 33.3%;
  }

  .member-avatar {
    margin: 0 auto;
    text-align: center;
    width: 88px;
    padding: 15px 0;
  }

  .pages-icon {
    width: 10%;
    margin-left: 50px;
  }

  .page-circle {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    margin: 10px 0;
  }

  .page-circle:hover {
    cursor: pointer;
  }

  .selected-circle {
    background: #FFFFFF;
  }
  
  .member-detail-purple {
    // background: url(/images/Home/purple_dialoge.png);
    // background-size: contain;
    // background-repeat: no-repeat;
    height: 320px;
    width: 340px;
  }

  .member-detail-black {
    // background: url(/images/Home/black_dialoge.png);
    // background-size: contain;
    // background-repeat: no-repeat;
    height: 340px; 
    width: 361px;
    position: absolute;
    top: 34px;
    left: 40px;
  }
  
  .member-detail-quote {
    height: 25px;
    width: 33px;
    position: absolute;
    top: 24px;
    left: 65px;
  }

  .member-detail-text {
    position: absolute;
    top: 100px;
    left: 100px; 
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    width: 257px;
    color: #FFFFFF;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 180px;
  }

  .member-detail-avatar {
    position: absolute;
    text-align: center;
    top: 370px;
    left: 167px;
  }

  .avatar-img {
    width: 60px;
    height: 60px;
    border-radius: 39px;
  }
  
  .avatar-name {
    font-size: 15px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
  }

  .avatar-nickname {
    font-size: 12px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #A3A3A3;
    line-height: 12px;
  }
</style>