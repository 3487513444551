<template>
  <div class="home">
    <Header></Header>
    <Map></Map>
    <About></About>
    <Industry></Industry>
    <Product></Product>
    <Member></Member>
    <Cooperation></Cooperation>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";

import Map from './com/map';
import About from './com/about';
import Industry from './com/industry';
import Product from './com/product';
import Member from './com/member';
import Cooperation from './com/cooperation';
import Footer from "@/components/Footer";

export default {
  data() {
    return {
      listheight:''
    };
  },
  components: {
    Header,
    Map,
    About,
    Industry,
    Product,
    Member,
    Cooperation,
    Footer
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 80
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }

}
</script>

<style scoped lang="scss">

.home {
  background-color: #000;
  width: 100%;
  // height: 100%;

  .swiper-container {
    width: 100%;
    height: 100%;

    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        height: 100%;
        color: #ffffff;
        background-color: #000;
        background-size: 100% 100%;
      }
    }
  }
}


</style>
