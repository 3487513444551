import request from '../utils/request';

//初始化数据
export const getMembers = () => {
    return request({
        url: process.env.VUE_APP_URL + 'Home/getMembers',
        method: 'get',
        params: {}
    });
};
