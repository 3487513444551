<template>
  <div class="Cooperation">
    <div class="eng-title">
      HIGH MOUNTAIN AND FLOWING
    </div>
    <div class="chs-box">
        <div class="line left-line"></div>
        <div class="chs-title">子期生生</div> 
        <div class="line right-line"></div>
    </div>
    <div class="logo-box">
        <div class="logo-row" style="width:75%">
            <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/浙江大学.png'" /></div>
            <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/北京大学.jpg'" /></div>
            <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/南京大学.png'" /></div>
            <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/厦门大学.jpg'" /></div>
            <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/网易联合创新中心.png'" style="height: 20px"/></div>
        </div>
        <div class="logo-row" style="width:75%">
          <div class="logo-item" style="padding: 10px 30px;"><img class="logo" :src="image_url + '/Home/cooperation/趣链.png'" style="height: 40px"/></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/e签宝.jpg'" /></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/中国电信.png'" style="height: 30px"/></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/天翼创投.jpg'" /></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/华润.png'" style="height: 70px"/></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/sotheby.png'"/></div>
        </div>
        <div class="logo-row" style="width:70%">
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/中航资本.png'" style="height:25px"/></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/蚂蚁.png'" style="height:40px"/></div>
          <div class="logo-item" style="padding: 10px 30px;"><img class="logo" :src="image_url + '/Home/cooperation/彩虹人生.png'" style="height:100px"/></div>
          <div class="logo-item" style="padding: 10px 30px;"><img class="logo" :src="image_url + '/Home/cooperation/求是强鹰.png'" style="height:90px"/></div>
          <div class="logo-item"><img class="logo" :src="image_url + '/Home/cooperation/商学.png'" style="height:30px"/></div>
        </div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "home_cooperation",
  data() {
    return {
      image_url: image_url
    }
  }
}
</script>

<style scoped lang="scss">
  .Cooperation {
    width: 100%;
    margin: 50px auto 0 auto;
  }

  .eng-title {
    text-align: center;
    width: 100%;
    font-size: 12px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #A3A3A3;
    line-height: 30px;
  }
  
  .chs-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .chs-title {
    font-size: 28px;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
    color: #FFFFFF;
  }

  .line {
    width: 35%;
    height: 1px;
    border: 1px solid;
    margin: 0 20px;
  }

  .left-line {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .right-line {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)); 
  }

  .logo-box {
    margin: 20px auto;
    padding: 20px 0;
    
  }
   
  .logo-row {
    margin: 50px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    height: 50px;
  }

  .logo-item {
    padding: 20px 15px;
    background: white;
    border-radius: 50px;
  }
</style>