<template>
  <div class="Map">
    <div class="map-box">
      <img class="map-bg" :src="image_url + '/Home/map_bg.png'"/>
      <div class="show-box" style="top: 365px; left: 245px">
        <div class="show-icon" @mouseover="showSpot(true, 0)" @mouseout="showSpot(false, 0)"></div>
        <div class="intro" :style="dialogeBg" v-show="isShow[0]">金字塔</div>
      </div>
      <div class="show-box" style="top: 420px; left: 905px">
        <div class="show-icon" @mouseover="showSpot(true, 1)" @mouseout="showSpot(false, 1)"></div>
        <div class="intro" :style="dialogeBg" v-show="isShow[1]">巴西耶稣像</div>
      </div>
      <div class="show-box" style="top: 305px; left: 458px; width: 100px;">
        <div class="show-icon" style="width: 90px; height: 30px" @mouseover="showSpot(true, 2)" @mouseout="showSpot(false, 2)"></div>
        <div class="intro" :style="dialogeBg" v-show="isShow[2]">万里长城</div>
      </div>
      <div class="bdo-box" style="top: 342px; left: 535px" @click="openPoster()" @mouseover="bdoHover(true)" @mouseleave="bdoHover(false)">
        <img :src="image_url + bdo_url"/>
      </div>
      <img class="icon center" :src="image_url + '/Home/center_icon.jpg'" @mouseover="showSpot(true, 3)" @mouseout="showSpot(false, 3)" />
      <div class="center-intro" v-show="isShow[3]">
        “在公元3056年，第一批三驱成员乘坐飞船，于茫茫星海中发现了一颗与地球相似的扁平蓝色星体。充满好奇心的三驱小队决定驻扎在此地，探索研究这颗与故土相似又奇异的星球。”
      </div>
      <img class="icon volcano" :src="image_url + '/Home/center_icon.jpg'" @mouseover="showSpot(true, 4)" @mouseout="showSpot(false, 4)"/>
      <img class="volcano-img" :src="image_url + '/Home/vocalno.png'" v-show="isShow[4]">
      <div class="enter-box" @click="openMap">
        <div>DIVE</div>
        <div class="dive">下潜</div>
      </div>
    </div>
    <div class="poster" v-show="isPosterShow">
      <div class="poster-wrapper">
        <div class="poster-content">
          <div class="intro intro-left">
            从三驱小队登陆于此，它就悬浮于空中。这两个巨大、神秘、对称的黑色物体，来历不明，无法进入，意义未知。三驱小队的科学家们对此各执一词：有的认为它象征着圆周率π，包含了各种各样的可能性，不仅存在于人类生活的底部，也可存在于宇宙的深处；
          </div>
          <div class="poster-img">
            <img :src="image_url + '/Home/bdo_poster.png'" />
          </div>
          <div class="intro intro-right">
            有的则主张它象征着混沌蝴蝶，正如世界的真实样态是动态而不稳定的，所谓的真相也并非轻易可以探查得到，因此需要连续地、整体地观察和思考世界的本质和边界；另外一部分人却坚持它是一扇通往美和未知的门，联系着人类的过去与将来。然而它究竟是什么，至今是3Q MAP的未解之谜。
          </div>
        </div>
        <div class="poster-title">Π型BDO</div>
        <div class="poster-close">
            <svg @click="closePoster()" t="1678002312668" class="close-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2758"><path d="M704.2 732.9c-6.9 0-13.8-2.6-19.1-7.9L295.2 335.1c-10.5-10.5-10.5-27.6 0-38.2 10.5-10.5 27.6-10.5 38.1 0l389.9 389.9c10.5 10.5 10.5 27.6 0 38.1-5.2 5.4-12.1 8-19 8z" fill="#ffffff" p-id="2759"></path><path d="M314.3 732.9c-6.9 0-13.8-2.6-19.1-7.9-10.5-10.5-10.5-27.6 0-38.1L685.1 297c10.5-10.5 27.6-10.5 38.1 0s10.5 27.6 0 38.2L333.3 725c-5.2 5.3-12.1 7.9-19 7.9z" fill="#ffffff" p-id="2760"></path><path d="M509.2 959.6c-246.9 0-447.8-200.9-447.8-447.8S262.3 64 509.2 64 957 264.8 957 511.8c0 246.9-200.8 447.8-447.8 447.8z m0-841.7c-217.2 0-393.9 176.7-393.9 393.9S292 905.7 509.2 905.7 903.1 729 903.1 511.8 726.4 117.9 509.2 117.9z" fill="#ffffff" p-id="2761"></path></svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {image_url } from '../../../const';

export default {
  name: "home_map",
  data() {
    return {
      isShow: [false, false, false, false, false],
      image_url: image_url,
      dialogeBg: {
        background: 'url(' + image_url + '/Home/dialoge.jpg)',
        backgroundSize: 'cover'
      },
      isPosterShow: false,
      bdo_url: "/Home/bdo.jpg"
    }
  },
  methods: {
    openMap(){
      this.$router.push('/Map')
      setTimeout(()=>{
        this.$router.go(0)
      },500)
    },
    showSpot(show, idx) {
      this.$set(this.isShow, idx, show);
    },
    openPoster() {
      this.isPosterShow = true;
      // 页面禁止滚动
      const element = document.querySelector('body');
      element.style.overflow = 'hidden';
    },
    closePoster() {
      this.isPosterShow = false;
      const element = document.querySelector('body');
      element.style.overflow = 'auto';
    },
    bdoHover(isHover) {
      if (isHover) {
        this.bdo_url = "/Home/bdo_hover.jpg";
      } else {
        this.bdo_url = "/Home/bdo.jpg";
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .Map{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 25px;
  }

  .map-box {
    position: relative;
    margin: 0 auto;
    // width: 100%;
  }

  .map-bg {
    width: 100%
  }

  .enter-box {
    position: absolute;
    cursor: pointer;
    bottom: 70px;
    left: 540px;
    width: 120px;
    margin: 0 auto;
    padding: 5px;
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    border-radius: 13px;

    div {
      font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      font-size: 30px;
      text-align: center;
    }

    a{
      width: 100%;
      height: 100%;
      color: #fff;
      text-decoration: none;
    }
  }

  .enter-box:hover{
    border: 2px solid #FFFFFF;
  }

  .show-box {
    width: 200px;
    position: absolute;
    z-index: 999;
  }

  .show-icon {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background: transparent
  }

  .icon {
    position: absolute;
    width: 50px;
  }

  .center {
    top: 300px;
    left: 392px;
    z-index: 3
  }

  .volcano {
    top: 307px;
    left: 281px;
    z-index: 5
  }

  .volcano-img {
    position: absolute;
    top: 330px;
    left: 303px;
    width: 320px; 
    z-index: 4;
    border-radius: 13px;
  }

  .center-intro {
    background: linear-gradient(180deg, #3D75E4 0%, #7C5FD7 100%);
    border-radius: 13px;
    font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    top: 327px;
    left: 420px;
    width: 320px;
    padding: 15px 20px;
    line-height: 30px;
    z-index: 1;
    font-size: 14px;
  }

  .intro {
    // background: url(/images/Home/dialoge.jpg);
    // background-size: cover;
    width: 150px;
    height: 32px;
    padding-top: 23px;
    margin: 0 auto;
    text-align: center;
    font-family: 'SourceHanSansCN-Bold', 'SourceHanSansCN';
    font-weight: bold;
  }

  .bdo-box {
    width: 150px;
    height: 103px;
    // border: 1px solid white;
    position: absolute;
    cursor: pointer;

    img {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .poster {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: 85px;
    // background: black;
    z-index: 9999;

    .poster-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1200px;
      height: 100%;
      background: black;
    }

    .poster-content {
      display: flex;
      width: 100%;
      height: 100%;
    }

    .intro {
      width: 25%;
      font-family: 'SourceHanSansCN-Medium', 'SourceHanSansCN';
      font-weight: 500;
      color: #FFFFFF;
      line-height: 30px;
      text-align: left;
      height: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .intro-left {
      padding-top: 30%;
    }

    .intro-right {
      padding-top: 35%;
    }

    .poster-img {
      width: 50%;
      height: 100%;
      img {
        object-fit: scale-down; 
        // max-height: 90%; 
        max-width: 100%;
        box-shadow: 0 0 6px 5px rgba(255, 255, 255, 0.5);
      }
    }

    .poster-title {
      font-family: 'SourceHanSansCN-Regular', 'SourceHanSansCN';
      font-weight: 400;
      color: #FFFFFF;
      line-height: 78px;
      text-shadow: 0px 1px 10px rgba(0,0,0,0.93);
      font-size: 70px;
      z-index: 9999;
      position: absolute;
      top: 10px;
      right: 150px;
    }

    .poster-close {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .close-icon {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
</style>